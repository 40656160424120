import { http } from '@/utils/http'

export interface BaseResult<T> {
  code: number
  msg: string
  data: T
}

export interface Agreement {
  id: number
  type: number
  title: string
  content: string
}

// 文件上传地址
export const uploadUrl = '/api/upload/image'

export const uploadUrl2 = '/api/upload/image2'

/**
 * 获取用户协议
 */
export const getUserAgreement = (type: number) => {
  return http.get<BaseResult<Agreement>>('/api/get_agreement/' + type)
}

// 获取业务配置
export const basicConfigAPI = () => {
  return http.get('/api/basic_config')
}

export const cityListAPI = () => {
  return http.get<BaseResult<any>>('/api/city_list')
}

// 带首字母的城市列表
export const getCityList = (cityLevel = 1, isWork = 0) => {
  return http.get(`/api/getCityList?city_level=${cityLevel}&is_work=${isWork}`)
}

// 公共参数
export const baseSettingAPI = () => {
  return http.get('/api/baseSetting')
}

// 枚举
export const selectOptionAPI = (fields: Array<string>) => {
  return http.post('/api/selectOption', { fields })
}

// 获取验证码
export function getAjcaptchaAPI(data) {
  return http.get('/api/ajcaptcha', data)
}

// 验证验证码
export function ajcaptchaCheckAPI(data) {
  return http.post('/api/ajcheck', data)
}

// 人机验证码-数据准备
export function getVerifyCodeAPI() {
  return http.get('/api/verify_code')
}
