import { reactive, readonly } from 'vue'

type ILoadingOptions = {
  size?: string
  text?: string
}

const state = reactive({
  show: false,
  size: '100px',
  text: '加载中...',
})

const useLoading = () => {
  const show = (options: ILoadingOptions = {}) => {
    state.size = options.size || '100px'
    state.text = options.text || '加载中...'
    state.show = true
  }
  const close = () => {
    state.show = false
  }
  return {
    show,
    close,

    state: readonly(state),
  }
}

export default useLoading
