<template>
  <view class="loading">
    <view class="loading_body" :style="rootStyle" :class="props.customClass">
      <view class="loading_item"></view>
    </view>
  </view>
</template>
<script lang="ts" setup>
import { ref, defineProps, watch, computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    required: false,
    default: '32px',
  },
  customClass: {
    type: String,
    required: false,
    default: '',
  },
})

const iconSize = ref<string | number>('32px')

watch(
  () => props.size,
  (newVal) => {
    iconSize.value = newVal
  },
  {
    deep: true,
    immediate: true,
  },
)

const rootStyle = computed(() => {
  return iconSize.value
    ? {
        width: iconSize.value,
        height: iconSize.value,
      }
    : {}
})
</script>

<style lang="scss" scoped>
@keyframes loadingAnimation {
  0% {
    background-image: url('@/static/images/loading/1.png');
  }
  5% {
    background-image: url('@/static/images/loading/2.png');
  }
  10% {
    background-image: url('@/static/images/loading/3.png');
  }
  15% {
    background-image: url('@/static/images/loading/4.png');
  }
  25% {
    background-image: url('@/static/images/loading/5.png');
  }
  35% {
    background-image: url('@/static/images/loading/6.png');
  }
  40% {
    background-image: url('@/static/images/loading/7.png');
  }
  45%,
  65% {
    background-image: url('@/static/images/loading/8.png');
  }
  75% {
    background-image: url('@/static/images/loading/7.png');
  }
  80% {
    background-image: url('@/static/images/loading/6.png');
  }
  85% {
    background-image: url('@/static/images/loading/5.png');
  }
  92% {
    background-image: url('@/static/images/loading/4.png');
  }
  95% {
    background-image: url('@/static/images/loading/3.png');
  }
  98% {
    background-image: url('@/static/images/loading/2.png');
  }
  100% {
    background-image: url('@/static/images/loading/1.png');
  }
}

.loading .loading_body .loading_item {
  width: 100%;
  height: 100%;
  background-size: cover;
  animation: loadingAnimation 1s infinite;
}
</style>
