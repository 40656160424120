<template>
  <wd-overlay :show="state.show">
    <view class="flex flex-col justify-center items-center h-full" @click="onClick">
      <w-loading :size="state.size" />
      <!-- <view class="bg-light-950 rounded-2 px-12 py-2 text-center">
        <w-loading :size="state.size" />
        <view class="mt-0 mb-2 text-white">{{ state.text }}</view>
      </view> -->
    </view>
  </wd-overlay>
</template>

<script lang="ts" setup>
import { ref, defineEmits, defineProps } from 'vue'
import useLoading from '@/hooks/useLoading'

const { state, close } = useLoading()

const emit = defineEmits(['click'])

const props = defineProps({
  show: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String,
    required: false,
    default: '80px',
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
})

const onClick = () => {
  emit('click')
}
</script>

<style lang="scss" scoped>
//
</style>
