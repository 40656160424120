import { defineStore } from 'pinia'
import { ref } from 'vue'
import { basicConfigAPI, baseSettingAPI } from '@/service/common'

export const useSettingStore = defineStore(
  'setting',
  () => {
    const setting: any = ref({})

    const loadSetting = async () => {
      const res: any = await Promise.all([basicConfigAPI(), baseSettingAPI()])
      console.log('load setting', res)

      setting.value = {
        ...res[0].data,
        ...res[1].data,
      }
    }

    return { setting, loadSetting }
  },
  {
    persist: true,
  },
)
