import { http } from '@/utils/http'

export const getUserInfoAPI = (fid = null) => {
  return http.post('/api/user/getUserInfo', {
    fid,
  })
}

export const loginH5 = (data: any) => {
  return http.post('login', data)
}

export const loginWeChatAPI = (data: any) => {
  return http.post('/api/login/wechat_login', data)
}

export const loginBySmsAPI = ({
  phone,
  captcha,
  spread = '',
  openid = '',
  unionid = '',
  isRegister = 0, // 登录传0, 注册传1
}) => {
  return http.post('/api/login/mobile', {
    phone,
    captcha,
    spread,
    openid,
    unionid,
    is_register: isRegister,
  })
}

export const loginByPasswordAPI = ({ account, password }) => {
  return http.post('/api/login', { account, password })
}

export const logoutAPI = () => {
  return http.get('/api/logout')
}

export const editAPI = (data: any) => {
  return http.post('/api/user/editUser', data)
}

/**
 * 绑定微信
 * @param code 微信授权返回的code
 */
export const bindWeChatAPI = (code: string) => {
  return http.post('/api/user/bindWechat', {
    code,
  })
}

// 发送短信验证码
export const sendSmsCodeAPI = (data: any) => {
  return http.post('/api/register/verify', data, {})
}

// APP微信登录
export const wechatAppAuth = (data: any) => {
  return http.post('/api/wechat/app_auth', data)
}

export const wechatAuthLogin = (params: any) => {
  return http.get('/api/v2/wechat/auth_login', params)
}

export const authLogin = (params: any) => {
  return http.get('/api/v2/routine/auth_login', params)
}

export const deficiencyInfo = (params: any) => {
  return http.post('/api/user/deficiencyInfo', params)
}

// 查看联系方式
export const getUserTel = (fid: number) => {
  return http.post('/api/user/getUserTel', { fid })
}

// 直约搜索助理信息
export const searchUser = (keyword: string) => {
  return http.post('/api/user/searchUser', { keyword })
}
