import { defineStore, acceptHMRUpdate } from 'pinia'
// import { getCurrentPages } from '@dcloudio/uni-app'

export type TabModeType = 'vip' | 'assistant'

export const TabMode = {
  vip: 'vip',
  assistant: 'assistant',
}

type Tab = {
  pagePath: string
  text: string
  icon: string
  iconActive: string
  iconType: string
}

export const ROOT_PAGE = '/pages/root/root'

const TABS_VIP = [
  {
    pagePath: '/pages/index/index',
    text: '广场',
    icon: '../../static/tabbar/index.png',
    iconActive: '../../static/tabbar/index-active.png',
    iconType: 'local',
  },
  {
    pagePath: '/pages/order/order',
    text: '订单',
    icon: '../../static/tabbar/order.png',
    iconActive: '../../static/tabbar/order-active.png',
    iconType: 'local',
  },
  {
    pagePath: '/pages/message/index',
    text: '消息',
    icon: '../../static/tabbar/message.png',
    iconActive: '../../static/tabbar/message-active.png',
    iconType: 'local',
  },
  {
    pagePath: '/pages/my/index',
    text: '我的',
    icon: '../../static/tabbar/me.png',
    iconActive: '../../static/tabbar/me-active.png',
    iconType: 'local',
  },
]

const TABS_ASSISTANT = [
  {
    pagePath: '/pages/order/order',
    text: '订单',
    icon: '../../static/tabbar/order.png',
    iconActive: '../../static/tabbar/order-active.png',
    iconType: 'local',
  },
  {
    pagePath: '/pages/message/index',
    text: '消息',
    icon: '../../static/tabbar/message.png',
    iconActive: '../../static/tabbar/message-active.png',
    iconType: 'local',
  },
  {
    pagePath: '/pages/my/index',
    text: '我的',
    icon: '../../static/tabbar/me.png',
    iconActive: '../../static/tabbar/me-active.png',
    iconType: 'local',
  },
]

export const useTabBarStore = defineStore(
  'tabbar',
  () => {
    // 当前tab的模式，分为vip模式和assistant模式
    const tabMode = ref(TabMode.vip)

    // 当前选中的tab
    const selectedTabId = ref('')

    // 底部选项卡，支持动态切换
    const tabs = ref(TABS_VIP)

    // 设置当前选中的tab
    const setSelectedTabId = (idx: string) => {
      selectedTabId.value = idx
    }

    const dotFetchVersion = ref(0)
    const dotFetch = () => {
      dotFetchVersion.value++
    }

    // 动态加载选项卡
    const reloadTabs = (mode: string, reloadPage = true) => {
      tabMode.value = mode
      tabs.value = tabMode.value === TabMode.vip ? TABS_VIP : TABS_VIP
      if (tabs.value.length > 0) {
        setSelectedTabId(tabs.value[0].pagePath)
      } else {
        console.warn('No tabs available after reload')
      }
      if (reloadPage) {
        redirectTo(ROOT_PAGE)
      }
    }

    // 切换tab
    const switchTab = (url: string) => {
      let tab = getTabBar(url)
      if (tab === undefined) {
        tab = tabs.value[0]
      }
      setSelectedTabId(tab.pagePath)
      // const pages = getCurrentPages()
      // console.log('pages', pages)

      // uni.redirectTo({ url: ROOT_PAGE })
      uni.setNavigationBarTitle({
        title: tab.text,
      })
    }

    const navigateTo = (url: string): void => {
      if (url.indexOf('/') !== 0) {
        url = '/' + url
      }
      console.log('navigateTo: ', url)
      console.log('tabs.value', tabs.value)

      const tab = getTabBar(url)
      if (tab !== undefined) {
        switchTab(tab.pagePath)
      } else {
        uni.navigateTo({ url })
      }
    }

    const navigateBack = (): void => {
      // #ifdef WEB
      window.history.back()
      // #endif
      // #ifndef H5
      uni.navigateBack()
      // #endif
    }

    const redirectTo = (url: string): void => {
      if (url.indexOf('/') !== 0) {
        url = '/' + url
      }
      console.log('redirectTo: ', url)

      const tab = getTabBar(url)
      console.log('tab: ', tab)

      if (tab !== undefined) {
        console.log('tab.pagePath: ', tab.pagePath)

        switchTab(tab.pagePath)
      } else {
        uni.redirectTo({ url })
      }
    }

    const getTabBarIndex = (url) => {
      console.log('tabs.value', tabs.value)
      const tabIndex = tabs.value.findIndex((tab) => tab.pagePath === url)
      console.log('tabIndex: ', tabIndex)
      return tabIndex
    }

    const getTabBar = (url) => {
      return tabs.value.find((tab) => tab.pagePath === url)
    }

    const resetToFirstTab = () => {
      if (tabs.value.length > 0) {
        setSelectedTabId(tabs.value[0].pagePath)
      }
    }

    return {
      resetToFirstTab,
      selectedTabId,
      setSelectedTabId,
      tabs,
      reloadTabs,
      switchTab,
      navigateTo,
      redirectTo,
      navigateBack,
      dotFetchVersion,
      dotFetch,
    }
  },
  {
    persist: true,
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTabBarStore, import.meta.hot))
}
