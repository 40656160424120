import { pages, subPackages } from '@/pages.json'

// /**
//  * 获取当前页面路由的 path 路劲和 redirectPath 路径
//  * path 如 ‘/pages/login/index’
//  * redirectPath 如 ‘/pages/demo/base/route-interceptor’
//  */
// export const currRoute = () => {
//   // getCurrentPages() 至少有1个元素，所以不再额外判断
//   const lastPage = getCurrentPages().at(-1)
//   const currRoute = (lastPage as any).$page
//   // console.log('lastPage.$page:', currRoute)
//   // console.log('lastPage.$page.fullpath:', currRoute.fullPath)
//   // console.log('lastPage.$page.options:', currRoute.options)
//   // console.log('lastPage.options:', (lastPage as any).options)
//   // 经过多端测试，只有 fullPath 靠谱，其他都不靠谱
//   const { fullPath } = currRoute as { fullPath: string }
//   // console.log(fullPath)
//   // eg: /pages/login/index?redirect=%2Fpages%2Fdemo%2Fbase%2Froute-interceptor (小程序)
//   // eg: /pages/login/index?redirect=%2Fpages%2Froute-interceptor%2Findex%3Fname%3Dfeige%26age%3D30(h5)
//   return getUrlObj(fullPath)
// }

const ensureDecodeURIComponent = (url: string) => {
  if (url.startsWith('%')) {
    return ensureDecodeURIComponent(decodeURIComponent(url))
  }
  return url
}
/**
 * 解析 url 得到 path 和 query
 * 比如输入url: /pages/login/index?redirect=%2Fpages%2Fdemo%2Fbase%2Froute-interceptor
 * 输出: {path: /pages/login/index, query: {redirect: /pages/demo/base/route-interceptor}}
 */
export const getUrlObj = (url: string) => {
  const [path, queryStr] = url.split('?')
  // console.log(path, queryStr)

  if (!queryStr) {
    return {
      path,
      query: {},
    }
  }
  const query: Record<string, string> = {}
  queryStr.split('&').forEach((item) => {
    const [key, value] = item.split('=')
    // console.log(key, value)
    query[key] = ensureDecodeURIComponent(value) // 这里需要统一 decodeURIComponent 一下，可以兼容h5和微信y
  })
  return { path, query }
}
/**
 * 得到所有的需要登录的pages，包括主包和分包的
 * 这里设计得通用一点，可以传递key作为判断依据，默认是 needLogin, 与 route-block 配对使用
 * 如果没有传 key，则表示所有的pages，如果传递了 key, 则表示通过 key 过滤
 */
export const getAllPages = (key = 'needLogin') => {
  // 这里处理主包
  const mainPages = [
    ...pages
      .filter((page) => !key || page[key])
      .map((page) => ({
        ...page,
        path: `/${page.path}`,
      })),
  ]
  // 这里处理分包
  const subPages: any[] = []
  subPackages.forEach((subPageObj) => {
    // console.log(subPageObj)
    const { root } = subPageObj

    subPageObj.pages
      .filter((page) => !key || page[key])
      .forEach((page: { path: string } & Record<string, any>) => {
        subPages.push({
          ...page,
          path: `/${root}/${page.path}`,
        })
      })
  })
  const result = [...mainPages, ...subPages]
  // console.log(`getAllPages by ${key} result: `, result)
  return result
}

/**
 * 得到所有的需要登录的pages，包括主包和分包的
 * 只得到 path 数组
 */
export const getNeedLoginPages = (): string[] => getAllPages('needLogin').map((page) => page.path)

/**
 * 得到所有的需要登录的pages，包括主包和分包的
 * 只得到 path 数组
 */
export const needLoginPages: string[] = getAllPages('needLogin').map((page) => page.path)

export function parseQuery() {
  const res = {}

  const query = (location.href.split('?')[1] || '').trim().replace(/^(\?|#|&)/, '')

  if (!query) {
    return res
  }

  query.split('&').forEach((param) => {
    const parts = param.replace(/\+/g, ' ').split('=')
    const key = decodeURIComponent(parts.shift())
    const val = parts.length > 0 ? decodeURIComponent(parts.join('=')) : null

    if (res[key] === undefined) {
      res[key] = val
    } else if (Array.isArray(res[key])) {
      res[key].push(val)
    } else {
      res[key] = [res[key], val]
    }
  })

  return res
}

export function getWechatParams() {
  const url = location.href
  const params: any = {}

  // 使用正则表达式匹配 code 和 state 参数
  const codeMatch = url.match(/[?&]code=([^&]+)/)
  const stateMatch = url.match(/[?&]state=([^&]+)/)

  if (codeMatch) {
    params.code = decodeURIComponent(codeMatch[1])
  }

  if (stateMatch) {
    params.state = decodeURIComponent(stateMatch[1])
  }

  return params
}

export function removeUrlParams(): string {
  const url = location.href
  // 分割 URL 和 hash
  const [baseUrl, hash] = url.split('#')

  // 移除主 URL 中的查询参数
  const cleanBaseUrl = baseUrl.split('?')[0]

  // 如果存在 hash，移除 hash 中的查询参数
  if (hash) {
    const cleanHash = hash.split('?')[0]
    return `${cleanBaseUrl}#${cleanHash}`
  }

  return cleanBaseUrl
}
