<template>
  <wd-config-provider :themeVars="themeVars">
    <slot />

    <!-- 注意下面，多了一个自定义tabbar -->
    <custom-tabbar />
    <wd-toast />
    <wd-message-box />
    <w-loading-toast />
  </wd-config-provider>
</template>

<script lang="ts" setup>
import type { ConfigProviderThemeVars } from 'wot-design-uni'

const themeVars: ConfigProviderThemeVars = {
  // colorTheme: 'red',
  // buttonPrimaryBgColor: '#07c160',
  // buttonPrimaryColor: '#07c160',
}
</script>
