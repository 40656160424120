import { TabModeType, useTabBarStore, ROOT_PAGE } from '@/store'
import Wechat from '@/utils/wechat'

export default function useRouter() {
  const store = useTabBarStore()

  const navigateTo = (url: string): void => {
    console.log('store', store)

    console.log('use router navigateTo', store.tabs)
    store.navigateTo(url)
  }

  const redirectTo = (url: string): void => {
    store.redirectTo(url)
  }

  const redirectToRoot = (url: string = '/pages/index/index'): void => {
    store.redirectTo(url)
    store.redirectTo(ROOT_PAGE + '?pagePath=' + url)
  }

  const back = (): void => {
    store.navigateBack()
  }

  const reloadTabs = (mode: string, reloadPage = true) => {
    store.reloadTabs(mode, reloadPage)
  }

  const toLogin = () => {
    // 微信网页特殊处理
    // #ifdef WEB
    if (Wechat.isWeixin()) {
      uni.redirectTo({
        url: '/pages/users/auth/wechat-login',
      })
    } else {
      uni.redirectTo({
        url: '/pages/users/auth/login',
      })
    }
    // #endif

    // #ifndef H5
    console.log('app平台')

    uni.redirectTo({
      url: '/pages/users/auth/login',
    })
    // #endif
  }

  // 跳转到对应的完善引导页
  const navigateToGuide = (index: number) => {
    // 小于0或者大于10，直接去个人中心
    if (index < 0 || index > 8) {
      redirectTo('/pages/my/index')
    }
    switch (index) {
      case 0:
        break
      case 1:
        navigateTo('/pages/guide/complete-user-1info')
        break
      case 2:
        navigateTo('/pages/guide/complete-user-2body')
        break
      case 3:
        navigateTo('/pages/guide/complete-user-3other')
        break
      case 4:
        navigateTo('/pages/guide/complete-user-4photo')
        break
      case 5:
        navigateTo('/pages/guide/complete-user-5tags')
        break
      case 6:
        navigateTo('/pages/guide/complete-user-6hobby')
        break
      case 7:
        navigateTo('/pages/guide/complete-user-7skill')
        break
      case 8:
        navigateTo('/pages/guide/complete-user-8salary')
        break
      case 9:
        redirectTo('/pages/my/index')
        break
    }
  }

  return { navigateTo, redirectTo, redirectToRoot, back, reloadTabs, navigateToGuide, toLogin }
}
