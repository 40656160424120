import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getUserInfoAPI, editAPI } from '@/service/user'
import JPush from '@/utils/jpush'

const initState = { nickname: '', avatar: '', now_money: 0 }

export const useUserStore = defineStore(
  'user',
  () => {
    const userInfo = ref<any>({ ...initState })
    const token = ref('')
    const uid = ref()
    const isLogined = ref(false)
    const isAssisstant = ref(false)

    const setUserInfo = (val: any) => {
      userInfo.value = {
        ...val,
        now_money: Number(val.now_money || 0).toFixed(0),
      }
      uid.value = val.uid
      isAssisstant.value = userInfo.value.type === 2
    }

    const clearUserInfo = () => {
      userInfo.value = { ...initState }
      token.value = ''
      uid.value = ''
      isLogined.value = false
      isAssisstant.value = false
    }

    // 一般没有reset需求，不需要的可以删除
    const reset = () => {
      userInfo.value = { ...initState }
    }

    /**
     * 登录
     */
    const login = async ({ token: apiToken, expireTime, userInfo: providedUserInfo }) => {
      token.value = apiToken
      isLogined.value = true
      if (providedUserInfo) {
        setUserInfo(providedUserInfo)
      } else {
        await reloadUserInfo()
      }

      // 处理登录后的事件
      await handlePostLoginEvents()
    }

    // 处理登录后的事件
    const handlePostLoginEvents = async () => {
      // #ifdef APP
      JPush.instance.setAlias({
        alias: userInfo.value.uid,
        sequence: 1,
      })

      JPush.instance.getRegistrationID(async (result) => {
        console.log('getRegistrationID result: ', JSON.stringify(result))
        const registerID = result.registerID
        console.log('registerID: ', registerID)
        if (registerID) {
          editAPI({
            registration_id: registerID,
          })
        }
      })
      // #endif
    }

    const setUid = (val: string) => {
      uid.value = val
    }

    const logout = () => {
      token.value = undefined
      uid.value = undefined
      userInfo.value = {}
      isLogined.value = false
      // 清除存储
      const keysToRemove = [
        'user',
        'payment',
        'setting',
        'tabbar',
        'openid',
        'unionid',
        'lastActiveTab',
        'token',
        'authIng',
        'buttonPosition',
        'vConsole_switch_x',
        'vConsole_switch_y',
      ]
      keysToRemove.forEach((key) => uni.removeStorageSync(key))
    }

    const reloadUserInfo = async (checkInfo = false) => {
      const userInfoResp = await getUserInfoAPI(0)
      const userInfo: any = userInfoResp.data
      // 缓存用户信息
      setUserInfo(userInfo)

      // 是否验证信息
      if (checkInfo) {
        // 助理
        if (isAssisstant.value) {
          // 未填写手机号, 跳转到注册后步骤1
          if (!userInfo.tel) {
            useRouter().redirectTo('/pages/guide/complete-user-1info')
          }
        }
        // 用户
        else {
          if (!userInfo.nickname) {
            // useRouter().redirectTo('/pages/users/profile/index')
          }
        }
      }
      return userInfo
    }

    return {
      token,
      userInfo,
      setUserInfo,
      clearUserInfo,
      isLogined,
      isAssisstant,
      reset,
      login,
      setUid,
      logout,
      reloadUserInfo,
    }
  },
  {
    persist: true,
  },
)
