import { http } from '@/utils/http'

export const getWechatConfigAPI = (url) => {
  return http.get('/api/wechat/config', url)
}

export const wechatAuthV2API = (code, spread) => {
  return http.get('/api/v2/wechat/auth', {
    code,
    spread,
  })
}

/**
 * 公众号登录
 * @returns {*}
 */
export function wechatAuthLoginAPI(data) {
  return http.get('/api/v2/wechat/auth_login', data)
}

/**
 * 获取商城是否强制绑定手机号
 */
export const getShopConfigAPI = () => {
  return http.get('/api/v2/bind_status')
}

/**
 * 后台版本信息
 *
 */
export function getSystemVersion() {
  return http.get(`/api/version`)
}

/**
 * 获取版本信息
 * @param 系统类型
 */
export function getUpdateInfo(type) {
  return http.get('/api/get_new_app/' + type)
}

/**
 * 获取用户权益商品
 *
 */
export function productsApi() {
  return http.get('/api/products')
}
