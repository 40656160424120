// #ifdef WEB
import { WechatJSSDK } from './jweixin'
// #endif
import { parseQuery } from '.'
import { getWechatConfigAPI, getShopConfigAPI, wechatAuthV2API } from '@/service/public'
import { ROOT_PAGE } from '@/store'

class AuthWechat {
  constructor() {
    // #ifdef WEB
    // 微信实例化对象
    this.instance = WechatJSSDK
    // #endif

    // #ifdef APP
    this.instance = null
    // #endif

    // 是否实例化
    this.status = false

    this.initConfig = {}
  }

  isAndroid() {
    const u = navigator.userAgent
    return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
  }

  signLink() {
    // if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
    // 	window.entryUrl = encodeURI(window.location.href)
    // }
    return /(Android)/i.test(navigator.userAgent) ? document.location.href : window.entryUrl
  }

  /**
   * 初始化wechat(分享配置)
   */
  wechat() {
    return new Promise((resolve, reject) => {
      // if (this.status && !this.isAndroid()) return resolve(this.instance);
      getWechatConfigAPI()
        .then((res) => {
          console.log('res.data', res.data)
          console.log('instance', this.instance)

          this.instance.config(res.data)
          this.initConfig = res.data
          this.status = true
          this.instance.ready(() => {
            resolve(this.instance)
          })
        })
        .catch((err) => {
          this.status = false
          reject(err)
        })
    })
  }

  /**
   * 跳转自动登陆
   */
  toAuth(snsapiBase, backUrl) {
    const that = this
    console.log('that.initConfig', that.initConfig)
    console.log('that.initConfig.appId', that.initConfig.appId)
    console.log('snsapiBase', snsapiBase)
    console.log('backUrl', backUrl)
    this.wechat().then((wx) => {
      console.log('开始跳转', wx)
      const url = this.getAuthUrl(this.initConfig.appId, snsapiBase, backUrl)
      console.log('url', url)
      location.href = url
    })
  }

  /**
   * 获取跳转授权后的地址
   * @param {Object} appId
   */
  getAuthUrl(appId, snsapiBase, backUrl) {
    // if (backUrl) {
    // 	let backUrlArr = backUrl.split('&');
    // 	let newUrlArr = backUrlArr.filter(item => {
    // 		if (item.indexOf('code=') === -1) {
    // 			return item;
    // 		}
    // 	});
    // 	backUrl = newUrlArr.join('&');
    // }

    // let url = `${location.origin}${backUrl}`
    // if (url.indexOf('?') === -1) {
    // 	url = url + '?'
    // } else {
    // 	url = url + '&'
    // }
    const redirectUri = encodeURIComponent(backUrl)
    console.log('redirectUri', redirectUri)

    const state = ROOT_PAGE
    // uni.setStorageSync(STATE_KEY, state);
    // uni.removeStorageSync(BACK_URL)
    if (snsapiBase === 'snsapi_base') {
      return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=${state}&connect_redirect=1#wechat_redirect`
    } else {
      return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=${state}&connect_redirect=1#wechat_redirect`
    }
  }

  /**
   * 自动去授权
   */
  oAuth(code, snsapiBase, url) {
    const cacheKey = 'wx-auth-code'
    const cachedCode = uni.getStorageSync(cacheKey)
    console.log('wechat.js ', cachedCode, code)
    console.log(!code, cachedCode !== code)
    if (!cachedCode || cachedCode !== code ) {
      uni.setStorageSync(cacheKey, code)
      return this.toAuth(snsapiBase, url)
    } else {
      // code重复授权
      console.log('code重复授权', code)
    }
  }

  // 获取经纬度；
  location() {
    return new Promise((resolve, reject) => {
      this.wechat()
        .then((wx) => {
          this.toPromise(wx.getLocation, {
            type: 'wgs84',
          })
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  // 使用微信内置地图查看位置接口；
  seeLocation(config) {
    return new Promise((resolve, reject) => {
      this.wechat()
        .then((wx) => {
          this.toPromise(wx.openLocation, config)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  /**
   * 微信支付
   * @param {Object} config
   */
  pay(config) {
    return new Promise((resolve, reject) => {
      this.wechat()
        .then((wx) => {
          this.toPromise(wx.chooseWXPay, config)
            .then((res) => {
              resolve(res)
            })
            .catch((res) => {
              reject(res)
            })
        })
        .catch((res) => {
          reject(res)
        })
    })
  }

  isWeixin() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
  }
}

export default new AuthWechat()
